/* eslint-disable react/jsx-props-no-spreading */
import { Select as AntSelect } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BaseSelectRef } from 'rc-select';
import { useEffect, useRef, useState } from 'react';
import {
  FieldError,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
  useForm,
} from 'react-hook-form';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import Button from './Button';
import Icon from './Icon';
import { StandardInputProps, TOption } from './iInput';
import { cn } from '@lib/utils';
dayjs.extend(utc);

export enum InputSelect {
  Select = 'select',
}

export enum InputOnlyName {
  Phone = 'tel',
  Text = 'text',
  TextArea = 'textarea',
  Date = 'date',
  Email = 'email',
  Number = 'number',
  Decimal = 'decimal',
  Checkbox = 'checkbox',
  Password = 'password',
}

export const InputName = { ...InputSelect, ...InputOnlyName };
export type InputNameType = InputSelect | InputOnlyName;

export type InputProps = StandardInputProps & {
  input:
    | 'tel'
    | 'text'
    | 'textarea'
    | 'date'
    | 'email'
    | 'number'
    | 'decimal'
    | 'checkbox'
    | 'password';
  decimalPoint?: number;

  rows?: number;
  cols?: number;
};

export type SelectInputProps = StandardInputProps & {
  input: 'select';
  setValue: UseFormSetValue<FieldValues | any>;
  clearErrors: () => void;
  options: TOption[];
  value: string | string[];
  multiple?: boolean;
};

export type TextAreaProps = InputProps & {
  input: 'textarea';
};

type InputWrapperProps<FormType extends FieldValues> = {
  label?: string;
  errors?: FieldErrors<FormType>;
  helpText?: string;
  inputProps: InputProps | SelectInputProps;
  labelClassName?: string;
  className?: string;
  optional?: boolean;
  optionalLabel?: string;
  defaultHideOptional?: boolean;
  hideError?: boolean;
  noMaterial?: boolean;
  hideExclamationIcon?: boolean;
  resetMargin?: boolean;
};

function SelectInput({
  input,
  name,
  setValue,
  value,
  onChange,
  options,
  disabled,
  placeholder,
  size = 'middle',
  multiple = false,
  onFocus,
  onBlur,
}: SelectInputProps) {
  const refSelect = useRef<BaseSelectRef>(null);
  const [autocompleteDisabled, setAutocompleteDisabled] = useState(false);
  const [search, setSearch] = useState('');
  const lowerCaseValue = search.toLowerCase();
  const filteredOptions =
    search.length > 0
      ? options.filter((option: TOption) => {
          if (
            String(option?.label).toLowerCase()?.indexOf(lowerCaseValue) >= 0 ||
            option.value.toLocaleLowerCase().indexOf(lowerCaseValue) >= 0
          ) {
            return true;
          }

          return false;
        })
      : options;

  useEffect(() => {
    // auto select for 1st option
    if (filteredOptions.length === 1) {
      console.log('currentvalue', value);
      const v = filteredOptions[0].value;
      let update = value;
      if (multiple) {
        // if (!value || value.length === 0) {
        //   update = [v];
        // } else {
        //   return;// dont update
        // }
        return;
      } else {
        update = v;
      }
      setValue(name, update, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      if (onChange) {
        onChange(update);
      }
      // setSearch('');// reset
    }
  }, [search]);

  const handleSearch = (v: string) => {
    setSearch(v);
  };

  const showSearch = options.length > 8;
  switch (input) {
    case InputSelect.Select:
      return (
        <>
          <AntSelect
            ref={refSelect}
            onFocus={(e) => {
              if (!autocompleteDisabled) {
                e.target.setAttribute('autocomplete', 'off');
                setAutocompleteDisabled(true);
              }
              if (onFocus) {
                onFocus(e);
              }
            }}
            onBlur={onBlur}
            id={name}
            disabled={disabled}
            size={size}
            allowClear
            showSearch={showSearch}
            showArrow
            {...(multiple ? { mode: 'multiple' } : {})}
            filterOption={false}
            placeholder={placeholder}
            value={value}
            onSearch={showSearch ? handleSearch : undefined}
            onChange={(v) => {
              setValue(name, v, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });

              if (onChange) {
                onChange(v);
              }
            }}
            options={filteredOptions}
          />
        </>
      );
    default:
      throw new Error(`Invalid input: ${input}`);
  }
}

function Input({
  input,
  name,
  register,
  value,
  min,
  max,
  maxlength,
  digitsOnly,
  decimalOnly,
  disabled,
  placeholder,
  decimalPoint = 2,
  onFocus,
  onBlur,
  onChange,
  setValue,
  prefix,
  rows = 3,
  cols,
  hideExclamationIcon,
}: InputProps | TextAreaProps) {
  let hookRegister = register!;
  let inputRef: HTMLInputElement | null = null;
  const [refLoaded, setRefLoaded] = useState(false);
  if (!register) {
    const {
      register: tReg,
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useForm({
      mode: 'all',
      defaultValues: {
        [name]: value,
      },
    });
    hookRegister = tReg;
  }
  const [inputValue, setInputValue] = useState(value || '+60');
  const hookProps = hookRegister(name);
  const previousValueRef = useRef('');

  // for phone
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const defaultValue =
    input === InputOnlyName.Date
      ? value instanceof Date
        ? dayjs(value).local().format('YYYY-MM-DD')
        : value || ''
      : value || '';

  useEffect(() => {
    if (input === InputOnlyName.Date && inputRef) {
      inputRef.value = defaultValue;
    }
  }, [value, inputRef]);

  switch (input) {
    case InputOnlyName.Phone:
      return (
        <div className="flex gap-1 border !border-gray-400 bg-white !rounded-md focus:!ring-0 focus:!border-blue-400 pt-2 pb-1 input-div">
          <Icon
            name="exclamation-mark"
            color="danger"
            className="error-icon absolute right-2 top-4"
          />
          <PhoneInput
            className="rounded-md bg-transparent w-full border-none"
            withCountryCallingCode
            defaultCountry="MY"
            placeholder={placeholder}
            disabled={disabled}
            value={inputValue}
            limitMaxLength
            id={name}
            onFocus={onFocus}
            onBlur={(e: any) => [
              hookProps.onBlur(e),
              onBlur ? onBlur(e) : null,
            ]}
            onChange={(v: any) => {
              const iValue = formatPhoneNumberIntl(v || '').trim() || v;

              const inputDom = { target: { value: iValue } };
              hookProps.onChange(inputDom);
              if (onChange) {
                onChange(inputDom);
              }
              setInputValue(iValue);
              if (setValue) {
                setValue(name, v, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                }); // use raw value (without spaces)
              } else {
                console.warn('no setValue', name);
              }
            }}
            ref={(r: any) => {
              hookProps.ref(r);
              if (r && !refLoaded && onChange) {
                setRefLoaded(true);

                if (r) {
                  const v = r.value;
                  onChange(v);
                }
              }
            }}
          />
        </div>
      );

    case InputOnlyName.Checkbox:
      return (
        <>
          {hideExclamationIcon ? (
            ''
          ) : (
            <Icon
              name="exclamation-mark"
              color="danger"
              className="error-icon absolute right-2 top-4"
            />
          )}
          <input
            className={cn(
              'focus:ring-0 focus:border-none rounded-md mt-0.5 w-full',
              prefix && ' pl-0'
            )}
            type={input}
            id={name}
            {...hookProps}
            value={'true'}
            onFocus={onFocus}
            onBlur={(e) => [hookProps.onBlur(e), onBlur ? onBlur(e) : null]}
            onChange={(e) => {
              hookProps.onChange(e);
              if (onChange) {
                onChange(e);
              }
            }}
            onSelect={(e) => {
              hookProps.onChange(e);
              if (onChange) {
                onChange(e);
              }
            }}
            min={min}
            max={max}
            defaultValue={value}
            placeholder={placeholder}
            disabled={disabled}
            ref={(r) => {
              hookProps.ref(r);
              if (r && !refLoaded && onChange) {
                setRefLoaded(true);
                if (input === InputOnlyName.Checkbox) {
                  onChange(r.checked);
                  return;
                }
                onChange(r.value);
              }
            }}
          />
        </>
      );

    case InputOnlyName.TextArea:
      return (
        <>
          <Icon
            name="exclamation-mark"
            color="danger"
            className="error-icon absolute right-2 top-4"
          />
          <div
            className="flex gap-1 border !border-gray-400 !rounded-md mt-0
            focus:!ring-0 focus:!border-blue-400 pt-2 bg-white input-div"
          >
            {!!prefix && <span className="mt-3 ml-3">{prefix}</span>}
            <textarea
              className={cn(
                'rounded-md pt-0 mt-3 w-full border-none focus:ring-0',
                prefix && ' pl-0'
              )}
              id={name}
              rows={rows}
              cols={cols}
              {...hookProps}
              onFocus={onFocus}
              onBlur={(e) => [hookProps.onBlur(e), onBlur ? onBlur(e) : null]}
              onChange={(e) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
                if (onChange) {
                  onChange(e);
                }
              }}
              defaultValue={value || ''}
              placeholder={placeholder}
              disabled={disabled}
              ref={(r) => {
                hookProps.ref(r);
                if (r && !refLoaded && onChange) {
                  setRefLoaded(true);
                  onChange(r.value);
                }
              }}
            />
          </div>
        </>
      );
    case InputOnlyName.Text:
    case InputOnlyName.Email:
    case InputOnlyName.Password:
    case InputOnlyName.Number:
    case InputOnlyName.Decimal:
    case InputOnlyName.Date:
      return (
        <>
          <Icon
            name="exclamation-mark"
            color="danger"
            className={cn(
              'error-icon absolute right-2',
              input === InputOnlyName.Date ? 'top-1' : 'top-4'
            )}
          />
          <div
            className="flex gap-1 border !border-gray-400 !rounded-md
            focus:!ring-0 focus:!border-blue-400 pt-2 bg-white input-div"
          >
            {!!prefix && <span className="mt-3 ml-3">{prefix}</span>}
            <input
              className={cn(
                'focus:ring-0 focus:border-none border-none rounded-md mt-0.5 w-full',
                prefix && 'pl-0'
              )}
              type={input === InputOnlyName.Decimal ? 'number' : input}
              {...(input === InputOnlyName.Decimal
                ? { step: (1 / 10 ** decimalPoint).toString() }
                : {})}
              id={name}
              {...hookProps}
              onFocus={onFocus}
              onBlur={(e) => {
                if (input !== InputOnlyName.Password) {
                  e.target.value = e.target.value.trim();
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                }

                if (input === InputOnlyName.Decimal) {
                  const v = parseFloat(e.target.value).toFixed(decimalPoint);
                  if (e.target.value !== v.toString()) {
                    inputRef!.value = v.toString();
                    e.target.value = v.toString();
                  }
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                }
                if (decimalOnly) {
                  const parsedValue = parseFloat(inputValue);
                  if (!isNaN(parsedValue)) {
                    const formattedValue = parsedValue.toFixed(2);
                    e.target.value = formattedValue;
                  }
                }
                hookProps.onBlur(e);
                if (onBlur) {
                  onBlur(e);
                }
              }}
              onClick={() => {
                if (
                  (input === InputOnlyName.Decimal ||
                    input === InputOnlyName.Number) &&
                  inputRef
                ) {
                  inputRef.select();
                }
              }}
              onChange={(e) => {
                if (input === InputOnlyName.Date) {
                  const v = e.target.value;
                  const dayjsDate = dayjs(v);
                  if (
                    dayjsDate.isValid() &&
                    dayjsDate.isAfter(dayjs('1997-01-01')) &&
                    dayjsDate.isBefore(dayjs().add(5, 'seconds'))
                  ) {
                    console.log('valid date', v, dayjsDate.toDate());
                    setValue(name, dayjsDate.format('YYYY-MM-DD'), {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                  }
                } else if (input === InputOnlyName.Decimal) {
                  const newValue = e.target.value;
                  if (
                    previousValueRef.current.includes('.') &&
                    !newValue.includes('.')
                  ) {
                    const valueLength = e.target.value.length;
                    e.target.type = 'text';
                    e.target.setSelectionRange(valueLength, valueLength);
                    e.target.type = 'number';
                  }
                  previousValueRef.current = e.target.value;
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                } else if (input === InputOnlyName.Number) {
                  setValue(name, parseInt(e.target.value, 10), {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                } else if (decimalOnly) {
                  const regex = /[^0-9.]/g;
                  e.target.value = e.target.value.replace(regex, '');
                  if (e.target.value.includes('.')) {
                    const decimal = e.target.value.split('.')[1];
                    if (decimal.length > 2) {
                      e.target.value = e.target.value.slice(0, -1);
                    }
                  }
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                } else {
                  if (digitsOnly) {
                    const regex = /[^0-9.]/g;
                    e.target.value = e.target.value.replace(regex, '');
                    if (e.target.value.includes('.')) {
                      const decimal = e.target.value.split('.')[1];
                      if (decimal.length > 2) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                    }
                  }
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                    shouldTouch: true,
                  });
                }

                if (onChange) {
                  onChange(e);
                }
              }}
              min={min}
              max={max}
              maxLength={maxlength}
              defaultValue={defaultValue}
              placeholder={placeholder}
              disabled={disabled}
              ref={(r) => {
                hookProps.ref(r);
                inputRef = r;
                if (r && !refLoaded && onChange) {
                  setRefLoaded(true);
                  if (input === InputOnlyName.Decimal) {
                    onChange(parseFloat(r.value));
                  } else if (input === InputOnlyName.Number) {
                    onChange(parseInt(r.value, 10));
                  } else {
                    onChange(r.value);
                  }
                }
              }}
            />
          </div>
        </>
      );

    default:
      throw new Error(`Invalid input: ${input}`);
  }
}

const renderInputs = ({
  name,
  options,
  input,
  register,
  setValue,
  onChange,
  clearErrors,
  min,
  max,
  maxlength,
  digitsOnly,
  decimalOnly,
  disabled,
  placeholder,
  checkboxLabel,
  onFocus,
  onBlur,
  isMaterial,
  prefix,
  checkboxLabelClassName,
  ...props
}: SelectInputProps | TextAreaProps | InputProps) => {
  const inputProp = props as InputProps;

  switch (input) {
    case InputName.Checkbox:
      return (
        <div className={cn('checkbox', checkboxLabelClassName)}>
          <Input
            isMaterial={isMaterial}
            onFocus={onFocus}
            onBlur={onBlur}
            input={input}
            name={`${name}`}
            register={register}
            setValue={setValue}
            value={inputProp.value}
            onChange={onChange}
            disabled={disabled}
            hideExclamationIcon={props.hideExclamationIcon}
          />
          <label className="ml-2" htmlFor={`${name}`}>
            <span>{checkboxLabel}</span>
          </label>
        </div>
      );

    case InputName.Select:
      // eslint-disable-next-line no-case-declarations
      const selectProps = props as SelectInputProps;
      // eslint-disable-next-line no-case-declarations
      const { multiple } = selectProps;
      return (
        <SelectInput
          isMaterial={isMaterial}
          onFocus={onFocus}
          onBlur={onBlur}
          multiple={multiple}
          input={input}
          name={name}
          clearErrors={clearErrors!}
          register={register}
          value={selectProps.value}
          setValue={setValue!}
          onChange={onChange}
          options={options!}
          min={min}
          max={max}
          disabled={disabled}
          placeholder={placeholder}
        />
      );

    default:
      return (
        <Input
          isMaterial={isMaterial}
          onFocus={onFocus}
          onBlur={onBlur}
          input={input as InputOnlyName}
          name={name}
          register={register}
          setValue={setValue}
          value={props.value}
          onChange={onChange}
          options={options}
          min={min}
          max={max}
          maxlength={maxlength}
          digitsOnly={digitsOnly}
          decimalOnly={decimalOnly}
          disabled={disabled}
          placeholder={isMaterial ? '' : placeholder}
          prefix={prefix}
        />
      );
  }
};

function InputWrapper<FormType extends FieldValues>({
  inputProps,
  helpText,
  className,
  label = '',
  labelClassName = '',
  errors = {} as FieldErrors<any>,
  optional = false,
  optionalLabel = 'Optional',
  defaultHideOptional = true,
  noMaterial = false,
  hideError,
  resetMargin,
}: InputWrapperProps<FormType>) {
  const [isFocused, setIsFocused] = useState(false);
  const [showOptional, setShowOptional] = useState(
    defaultHideOptional ? false : !!inputProps.value
  );
  const hasErrors = Object.prototype.hasOwnProperty.call(
    errors,
    inputProps.name
  );
  if (optional && !showOptional) {
    return (
      <div className={cn('md:mb-4', className)}>
        <Button
          className="inline-block"
          asText
          onClick={() => setShowOptional(true)}
        >
          {optionalLabel}
        </Button>
      </div>
    );
  }
  // console.log('input', inputProps.name, inputProps.setValue);
  // const isForm = inputProps.input === InputName.Text || inputProps.input === InputName.Email
  //   || inputProps.input === InputName.Phone || inputProps.input === InputName.Password
  //   || inputProps.input === InputName.Number || inputProps.input === InputName.Decimal
  //   || inputProps.input === InputName.Date || inputProps.input === InputName.Checkbox;
  const isMaterial: boolean =
    !noMaterial && label
      ? [
          InputName.Select,
          InputName.Text,
          InputName.TextArea,
          InputName.Number,
          InputName.Email,
          InputName.Password,
          InputName.Phone,
          InputName.Decimal,
          InputName.Date,
        ].indexOf(inputProps.input as InputOnlyName) >= 0
      : false;
  const err: FieldError | undefined = (errors as FieldValues)[inputProps.name];
  // console.log('inputprops', inputProps.name, {...inputProps});
  return (
    <div
      className={cn(
        'input-wrapper',
        className,
        isMaterial && 'material',
        inputProps.disabled && 'disabled',
        inputProps.input === InputName.Checkbox && inputProps.input,
        inputProps.input === InputName.Select && 'select',
        inputProps.input === InputName.Select &&
          inputProps.multiple &&
          'multiple',
        !resetMargin && 'mb-4'
      )}
      data-error={hasErrors}
    >
      {label && (
        <label
          htmlFor={inputProps.name}
          className={cn(
            labelClassName,
            isFocused && 'active',
            inputProps.input === InputName.Date && 'filled',
            inputProps.prefix && 'filled',
            typeof inputProps.value !== 'undefined' &&
              inputProps.value !== null &&
              inputProps.value?.toString().length > 0 &&
              'filled',
            !resetMargin && 'mb-2'
          )}
        >
          {label}
        </label>
      )}
      {renderInputs({
        ...inputProps,
        isMaterial,

        onFocus: () => [setIsFocused(true)],
        onChange: (e) => {
          if (e === null || typeof e === 'undefined') {
            if (inputProps.onChange) {
              inputProps.onChange(e);
            }
            return;
          }
          if (inputProps.input === InputName.Checkbox) {
            if (typeof e === 'object') {
              if (inputProps.onChange) {
                inputProps.onChange(e.target.checked);
              }
            } else {
              if (inputProps.onChange) {
                inputProps.onChange(e);
              }
            }

            return;
          }

          if (typeof e === 'object' && !Array.isArray(e)) {
            if (inputProps.onChange) {
              inputProps.onChange(e.target.value);
            }
          } else {
            if (inputProps.onChange) {
              inputProps.onChange(e);
            }
          }
        },
        onBlur: () => setIsFocused(false),
      })}
      {helpText && <p className="input-text">{helpText}</p>}
      {!hideError && hasErrors && <p className="input-text">{err?.message}</p>}
    </div>
  );
}

export default InputWrapper;
